.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: 14px;
  /* border-radius: 12px; */
  overflow: hidden;
  color: #ffffff;
  /* background: #101233; */
  /* box-shadow: inset 0 0 30px 5px hsl(0deg 0% 100% / 1%); */
  /* text-transform: uppercase; */
}
.Tab.block.tab-new-style-fullwidth {
  background: transparent;
  border-radius: 16px 16px 0px 0px;
  border-bottom: 1px solid var(--Border, rgba(55, 63, 92, 0.5));
}
.Tab.block.tab-new-style-fullwidth .Tab-option {
  padding: 16px 24px;
}
.Tab.block.tab-new-style-fullwidth.Tab.block .Tab-option {
  color: #828899;
  line-height: 140%;
}
.Tab.block.tab-new-style-fullwidth.Tab.block .Tab-option.active {
  border-radius: 16px 16px 0px 0px;
  border-bottom: 2px solid var(--Primary, #90fb75);
  background: var(--Nature-2, #1a1d26);
  color: #90fb75;
}
.Tab.block.tab-new-style-fullwidth.Tab.block .Tab-option:hover {
  background: unset;
  border-radius: 16px 16px 0px 0px;
}
.Tab-option-icon {
  margin-right: 0.465rem;
  /* opacity: 0.7; */
}
.Exchange-list-tab-container {
  border-bottom: 1px solid var(--Border, rgba(55, 63, 92, 0.5));
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-weight: 700;
}

.Tab.block .Tab-option:hover {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: #2e3033;
  border-radius: 100px;
}
.Tab.block .Tab-option.long {
  opacity: 1;
  color: #000000;
}
.Tab.block .Tab-option.short {
  opacity: 1;
  color: #ffffff;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  padding: 12px 16px;
  /* margin-right: 1.5rem; */
  font-size: var(--font-base);
  border-radius: 12px;
  color: #828899;
}

.Tab.inline .Tab-option:hover {
  /* opacity: 0.8; */
  /* background: #212333; */
  color: rgba(255, 255, 255, 1);
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  /* background: #212333; */
  color: rgba(255, 255, 255, 1);
}

.icon-newtab {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}
