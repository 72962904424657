.BuyNAVINLP {
  /* background: #101124; */
  justify-content: space-between;
}

.BuyNAVINLP-container {
  padding: 0 0 3.1rem 0;
  width: 100%;
}

.BuyNAVINLP-container .section-title-content {
  justify-content: flex-start;
}
