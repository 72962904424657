.Checkbox {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Checkbox.disabled {
  cursor: default;
  pointer-events: none;
}

.Checkbox-icon-wrapper {
  display: inline-flex;
  align-items: center;
  min-width: 22.19px;
}

.Checkbox .Checkbox-icon {
  font-size: 1.25rem;
  color: #02f6af;
  margin-right: 0.62rem;
  margin-bottom: 0;
  border-radius: 4px;
}

.Checkbox.disabled .Checkbox-icon {
  color: #47509e;
}

.Checkbox-icon.inactive {
  color: rgba(255, 255, 255, 0.7);
}

.Checkbox-label {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
}
