.TradeHistory-row {
  font-size: var(--font-sm);
  padding: 1.5rem;
  margin-bottom: 0.8rem;
}

.TradeHistory-time {
  font-size: 1.25rem;
  margin-bottom: 0.155rem;
}

.TradeHistory.with-scroll {
  overflow-y: auto;
}

.TradeHistory:has(svg.shareic) a:hover path {
  fill: #90FB75;
}
/*
@media screen and (min-width: 1110px) {
  .TradeHistory.with-scroll {
    height: 300px;
  }
} */
