$primary-color: #28a745;
$secondary-color: #828899;
$background-color: #f8f9fa;

.migrate-container {
  // font-family: Arial, sans-serif;
  background-color: #12141a;
  width: 100%;
  padding: 24px;
  border-radius: 16px;

  .BeginAccountTransfer-validations {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 0;
    margin-top: 12px;
  }

  .input-container {
    padding: 16px;
    width: fit-content;
    border-radius: 100px;
    border: 1px solid var(--Border, rgba(55, 63, 92, 0.5));
    margin-top: 24px;
    @media screen and (max-width: 450px) {
      width: 100%;
    }
    input {
      padding: 0 !important;
      width: 350px;
      font-size: 14px !important;
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  }

  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  .button-actions {
    display: flex;
    gap: 12px;

    button {
      flex: 1;
      width: unset;
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  .migrate-header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    h2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 4x;
    }

    p {
      color: $secondary-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }

  .migrate-content {
    display: flex;
    gap: 20px;

    .migrate-box {
      flex: 1;
      background: #1a1d26;
      padding: 24px;
      border-radius: 16px;

      // border: 1px solid #ddd;
      a {
        text-decoration: none !important;

        button {
          text-decoration: none !important;
        }
      }

      h3 {
        color: $secondary-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 8px;
      }

      .migrate-list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        gap: 12px;

        .tokens {
          display: flex;
          align-items: center;
          gap: 24px;

          .token {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;

            & .coin {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    .migrate-claim {
      .tokens {
        @media screen and (max-width: 1023px) {
        }

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: stretch !important;
        }
      }
    }
  }

  @media screen and (max-width: 1320px) {
    .migrate-content {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 16px;

    .migrate-content {
      gap: 16px;

      .migrate-box {
        padding: 16px;
      }
    }
  }
}

.transaction-table {
  // margin-top: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  @media screen and (max-width: 900px) {
    display: none;
  }

  .transaction-header {
    color: #828899;
    padding: 0 16px;

    .table-column {
      padding-left: 6px;

      &:first-child {
        padding: 0;
      }

      &:last-child {
        padding: 0;
      }
    }
  }

  .transaction-line {
    display: flex;
    font-weight: 500;

    .table-column {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 8px;

      &:last-child {
        flex: none;
        width: fit-content !important;
      }
    }

    .column-view {
      svg {
        &:hover {
          path {
            fill: #90fb75;
          }
        }
      }
    }

    .completed {
      color: #03f5ae !important;
    }

    .delivered {
      color: #03f5ae !important;
    }

    .pending_claim {
      color: #ffb200 !important;
    }

    .failed {
      color: #f6475d !important;
    }

    .inflight {
      color: #ffb200 !important;
    }
  }

  .transaction-row {
    background: #12141a;
    border-radius: 100px;
    padding: 16px;
    margin-top: 8px;
    // cursor: pointer;
  }
}

.transaction-table-mobile {
  display: none;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;

    .table-mobile-content {
      background: #12141a;
      border-radius: 16px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .content-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        .title {
          color: #828899;
        }

        .value {
          display: flex;
          align-items: center;
          gap: 8px;

          & > div {
            display: flex;
            align-items: center;
            gap: 4px;

            &.delivered {
              color: #03f5ae !important;
            }

            &.failed {
              color: #f6475d !important;
            }

            &.inflight {
              color: #ffb200 !important;
            }
          }
        }
      }
    }
  }

  .completed {
    color: #03f5ae !important;
  }

  .failed {
    color: #f6475d !important;
  }

  .inflight {
    color: #ffb200 !important;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.Migrate-Section {
  @media screen and (max-width: 1320px) {
    .history-table,
    .pending-reciever {
      margin-top: 48px !important;
    }
  }
}

.vesting-table {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 900px) {
    display: none;
  }

  .transaction-header {
    padding: 0 !important;
  }

  .transaction-content {
    gap: 12px;
    display: flex;
    flex-direction: column;

    .transaction-line {
      background: none !important;
      padding: 0 !important;
    }
  }
}
