.PositionDropdown-dots-icon {
  font-size: 1rem;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  display: inline-flex;
  align-items: center;
  margin-top: 0.3rem;
  background: rgba(255, 255, 255, 0);
  border-radius: 2rem;
  padding: 0.5rem;
}

.PositionDropdown-dots-icon:hover {
  color: rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 0.1);
}

.PositionDropdown-extra-options {
  position: relative;
}

.PositionDropdown-extra-options .menu-items {
  top: 1rem;
  min-width: 17.5rem;
}

.btn-dropdown {
  .icon-down {
    position: absolute;
    top: 12px;
    right: 8px;
  }
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  width: 160px;
  height: 48px;
  border: none;
  position: relative;
  display: flex;
  align-items: baseline;
  padding: 8px 12px;
  .content-dropdown {
    color: #fff;
    display: flex;
    height: 100%;
    align-items: center;
    width: 128px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    span {
      font-weight: 400;
      font-size: 12px;
      color: #828899;
    }
  }
}
