.Collateral-modal-deposit {
  padding: 12px 16px !important;

  .NlpSwap-from-token {
    // pointer-events: none;

    // .TokenSelector-box svg {
    //   display: none;
    // }
  }

  .Modal-content {
    max-width: 424px !important;
    width: 100% !important;

    .Modal-title-bar {
      margin: 0;
      padding: 12px 16px !important;
    }

    .Modal-body {
      // margin-bottom: 24px !important;
    }
  }

  .Modal-description {
    font-size: 14px;
  }

  .exchange-section-first {
    margin: 16px 0px !important;
  }

  .Exchange-swap-ball {
    top: -26px !important;

    .Exchange-swap-ball-icon {
      transform: rotate(0deg) !important;

      width: 12px;
      height: auto;
    }
  }

  .Exchange-swap-max {
    padding: 2px 12px !important;

    &:hover {
      opacity: 0.8;
    }
  }

  .Exchange-swap-section,
  .Exchange-info-row {
    margin-bottom: 16px !important;
  }

  .route-deposit-container {
    margin-bottom: 8px !important;
  }

  .Tooltip-popup {
    background: #262933 !important;
    border-radius: 12px !important;
    color: #828899 !important;
  }

  .TokenSelector {
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;

      &:hover {
        cursor: text;
      }
    }
  }
}

.theme--light {
  .Tooltip-popup {
    background: var(--Background-Tooltip_Background, #5e6673) !important;
    color: var(--Text-Text_Button, #fff) !important;
  }
}
