.ant-pagination-mini {
  display: flex;
  justify-content: flex-end;
  font-family: "Roboto";
  margin-top: 24px;
  color: #fff;
  gap: 8px;

  @media screen and (max-width: 400px) {
    gap: 4px;
  }

  > li {
    height: 40px !important;
  }

  * {
    color: #fff;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid rgba(55, 63, 92, 0.5);
    border-radius: 100px !important;

    &:hover {
      border: 1px solid white;
    }
  }

  .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px 16px;
    border: 1px solid rgba(55, 63, 92, 0.5);
    border-radius: 100px !important;

    a {
      color: #fff !important;
      font-weight: 500;
    }
  }

  .ant-pagination-item-active {
    background-color: #03f5ae;

    a {
      color: #fff !important;
    }
  }

  .ant-pagination-item-active:hover {
    border-color: transparent;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    width: 40px !important;
    height: 40px;
    display: flex;
    border: 1px solid rgba(55, 63, 92, 0.5);
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    line-height: unset;
  }

  .ant-pagination-item-ellipsis {
    color: #fff !important;
  }
}

.pagination-v2 {
  display: flex;
  justify-content: flex-end;
  font-family: "Roboto";
  margin-top: 16px;
  color: #fff;

  > li {
    height: 40px !important;
  }

  * {
    color: #fff;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: var(--Border-Radius-Medium, 100px);
    border: 1px solid rgba(55, 63, 92, 0.5);

    &:hover {
      border: 1px solid white;
    }
  }

  .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px 16px;
    border-radius: var(--Border-Radius-Medium, 100px);
    border: 1px solid rgba(55, 63, 92, 0.5);
    font-weight: 500;

    a {
      color: #fff !important;
      font-weight: 500;
      font-family: "DM Sans";
      font-weight: 500;
    }

    &:hover {
      border: 1px solid white;
    }
  }

  .ant-pagination-item.ant-pagination-item-active {
    border-radius: var(--Border-Radius-Medium, 100px);
    border: 1px solid #90fb75 !important;
    // background: var(--Nature-3, #2e3033);
    color: #90fb75 !important;

    a {
      color: #90fb75 !important;
    }

    // &:hover {
    //   background: #03f5ae !important;
    //   border: none !important;
    // }
  }

  .ant-pagination-item-active:hover {
    border-color: transparent;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    width: 40px !important;
    height: 40px;
    display: flex;
    border-radius: var(--Border-Radius-Medium, 100px);
    border: 1px solid rgba(55, 63, 92, 0.5);
    justify-content: center;
    align-items: center;
    line-height: unset;
    padding-bottom: 0;

    &:hover {
      border: 1px solid white;
    }
  }

  .ant-pagination-item-ellipsis {
    color: #fff !important;
  }

  // .ant-pagination-item-active {
  //   a {
  //     color: black !important;
  //   }
  // }
  .ant-pagination-prev {
    padding-bottom: 0 !important;
  }

  .ant-pagination-next {
    padding-bottom: 0 !important;
  }
}
