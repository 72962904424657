.SwapBox-collateral-tooltip-text {
  font-weight: normal;
}

.label-box-title--active {
  color: #90fb75 !important;
  cursor: no-drop;

  border-radius: 100px;

  background: var(--Nature-3, #262933);
}